import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import Papa from 'papaparse';
import fontsCSV from './fonts.csv';
import './App.css';
import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';

// Import the JSON files
import initialPositions from './initialPositions.json';
import teksty from './teksty.json';

const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi03PXeFLDSQJg45YbPn1
mw5zQILjPiSaUkXz7vVHG/TIOEjrmcuUPCEQPf4j3ZnnZ3VxKeqhDj6BMd4rWWXX
HEgjAdmNlSlvb2oS5NUwON2WkTLn/JrRYqLGMI+rXnxjUjMa7lfbeFrtZC4+4cmq
oRnZLT3y5SVB0RAVmv7cXnTx/MeUSojUgpf0SjNZ6TvNBUexJlajHxRy0MWxzmje
2CfLBHPYTO6S5s+9tm5/8a937sIqB4c+AVpqIOgFfkvImZ53TtVYT8CV4RUiCl6m
p7hr80gIiDXDGuNiErXHdPVzfB1F3fLbh/OSQ6WwKS7PuOIz/nAnQOMrCvRjPlhr
4wIDAQAB
-----END PUBLIC KEY-----
`;

// Background Component //////////////////////////////////////////////////////
// This component renders the background grid and the white box on the canvas.
function Background({ W, H }) {
  return (
    <div className="background">
      <div className="overlay"></div>
      <div className="white-box" style={{ width: `${W}px`, height: `${H}px` }}></div>
      {Array.from({ length: W / 10 + 1 }).map((_, index) => (
        <React.Fragment key={index}>
          <div className={`vertical-line ${index % 10 === 0 ? 'long' : 'short'}`} style={{ left: `${50 + index * 10}px` }}></div>
          {index % 10 === 0 && index !== 0 && (
            <div className="vertical-label" style={{ left: `${50 + index * 10}px` }}>
              {index / 10}
            </div>
          )}
        </React.Fragment>
      ))}
      {Array.from({ length: H / 10 + 1 }).map((_, index) => (
        <React.Fragment key={index}>
          <div className={`horizontal-line ${index % 10 === 0 ? 'long' : 'short'}`} style={{ top: `${50 + index * 10}px` }}></div>
          {index % 10 === 0 && index !== 0 && (
            <div className="horizontal-label" style={{ top: `${50 + index * 10}px` }}>
              {index / 10}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

// DraggableText Component ///////////////////////////////////////////////////
// This component represents a draggable text element.
function DraggableText({ id, initialPosition, updateTextData, setSelectedElement, fontFamily, fontSize, letterSpacing, wordSpacing, fontWeight, fontStyle, textDecoration, text, isSelected, rotate }) {
  const [position, setPosition] = useState(initialPosition);
  const [isEditing, setIsEditing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition, id]);

  const handleDrag = (e, d) => {
    setPosition({ x: d.x, y: d.y });
    updateTextData(id, { position: { x: d.x, y: d.y } });
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (e) => {
    const newText = e.target.innerText;
    updateTextData(id, { text: newText });
    setIsEditing(false);
  };

  const calculateSize = () => {
    
    return { width: 'auto', height: `${fontSize}px` };
  };

  return (
    <Rnd
      size={calculateSize()}
      position={{ x: position.x, y: position.y }}
      enableResizing={false}
      bounds={false}
      onDrag={handleDrag}
      className={`draggable-text ${isSelected ? 'selected' : ''}`}
      onClick={() => setSelectedElement({ type: 'text', id })}
      dragHandleClassName="drag-handle"
    >
      {isEditing ? (
        <div
          ref={textRef}
          contentEditable
          suppressContentEditableWarning
          style={{ fontSize: `${fontSize}px`, whiteSpace: 'nowrap', fontFamily, letterSpacing: `${letterSpacing}px`, wordSpacing: `${wordSpacing}px`, fontWeight, fontStyle, textDecoration, transform: `rotate(${rotate}deg)` }}
          onBlur={handleBlur}
          onKeyPress={(e) => { if (e.key === 'Enter') e.target.blur(); }}
        >
          {text}
        </div>
      ) : (
        <div
          ref={textRef}
          style={{ fontSize: `${fontSize}px`, whiteSpace: 'nowrap', fontFamily, letterSpacing: `${letterSpacing}px`, wordSpacing: `${wordSpacing}px`, fontWeight, fontStyle, textDecoration, transform: `rotate(${rotate}deg)` }}
          onDoubleClick={handleDoubleClick}
        >
          {text}
        </div>
      )}
      <div className="drag-handle"></div>
    </Rnd>
  );
}

// DraggableRectangle Component
function DraggableRectangle({ id, initialPosition, width, height, borderWidth, rotate, updateRectangleData, setSelectedElement, isSelected }) {
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition, id]);

  const handleDrag = (e, d) => {
    setPosition({ x: d.x, y: d.y });
    updateRectangleData(id, { position: { x: d.x, y: d.y } });
  };

  return (
    <Rnd
      size={{ width, height }}
      position={{ x: position.x, y: position.y }}
      enableResizing={{ top: false, right: true, bottom: true, left: false, topRight: true, bottomRight: true, bottomLeft: true, topLeft: true }}
      bounds={false}
      onDrag={handleDrag}
      className={`draggable-rectangle ${isSelected ? 'selected' : ''}`}
      onClick={() => setSelectedElement({ type: 'rectangle', id })}
      dragHandleClassName="drag-handle"
    >
      <div
        className="rectangle"
        style={{
          width: '100%',
          height: '100%',
          border: `${borderWidth}px solid black`,
          transform: `rotate(${rotate}deg)`
        }}
      >
        <div className="drag-handle"></div> {/* Koła do przeciągania */}
      </div>
    </Rnd>
  );
}



// DraggableLine Component
function DraggableLine({ id, initialPosition, length, thickness, rotate, updateLineData, setSelectedElement, isSelected }) {
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition, id]);

  const handleDrag = (e, d) => {
    setPosition({ x: d.x, y: d.y });
    updateLineData(id, { position: { x: d.x, y: d.y } });
  };

  return (
    <Rnd
      size={{ width: length, height: thickness }}
      position={{ x: position.x, y: position.y }}
      enableResizing={false}
      bounds={false}
      onDrag={handleDrag}
      className={`draggable-line ${isSelected ? 'selected' : ''}`}
      onClick={() => setSelectedElement({ type: 'line', id })}
      dragHandleClassName="drag-handle"
      style={{ zIndex: 3 }} // Linie
    >
      <div
        className="line"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          transform: `rotate(${rotate}deg)`
        }}
      >
        <div className="drag-handle" style={{ zIndex: 5 }}></div> {/* Koła do przeciągania */}
      </div>
    </Rnd>
  );
}

// DraggableImage Component
function DraggableImage({ id, initialPosition, src, width, height, rotate, updateImageData, setSelectedElement, isSelected }) {
  const [position, setPosition] = useState(initialPosition);
  const [size, setSize] = useState({ width, height });

  useEffect(() => {
    setPosition(initialPosition);
    setSize({ width, height });
  }, [initialPosition, id, width, height]);

  const handleDrag = (e, d) => {
    setPosition({ x: d.x, y: d.y });
    updateImageData(id, { position: { x: d.x, y: d.y } });
  };

  const handleResize = (e, direction, ref, delta, position) => {
    setSize({
      width: ref.style.width,
      height: ref.style.height,
    });
    setPosition(position);
    updateImageData(id, { width: ref.style.width, height: ref.style.height, position });
  };

  return (
    <Rnd
      size={size}
      position={position}
      enableResizing={{ top: true, right: true, bottom: true, left: true, topRight: true, bottomRight: true, bottomLeft: true, topLeft: true }}
      bounds={false}
      onDrag={handleDrag}
      onResize={handleResize}
      className={`draggable-image ${isSelected ? 'selected' : ''}`}
      onClick={() => setSelectedElement({ type: 'image', id })}
      dragHandleClassName="drag-handle"
      style={{ zIndex: 4 }} // Obrazek
    >
      <img
        src={src}
        alt="Draggable"
        style={{
          width: '100%',
          height: '100%',
          transform: `rotate(${rotate}deg)`
        }}
      />
      <div className="drag-handle" style={{ zIndex: 5 }}></div> {/* Koła do przeciągania */}
    </Rnd>
  );
}

// App Component /////////////////////////////////////////////////////////////
// This is the main component of the application, responsible for rendering all draggable elements and handling state.
function App() {
  const [W, setW] = useState(null);
  const [H, setH] = useState(null);
  const [aesKey, setAesKey] = useState('');
  const [iv, setIv] = useState('');
  const [encryptedXmlData, setEncryptedXmlData] = useState('');
  const [textData, setTextData] = useState([]);
  const [rectangleData, setRectangleData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [xmlOutput, setXmlOutput] = useState('');
  const [fontsData, setFontsData] = useState([]);
  const [selectedName, setSelectedName] = useState('Printer 40');
  const [selectedElement, setSelectedElement] = useState(null);
  const initialTextData = useRef([]);
  const imageInputRef = useRef(null);

  // useEffect to load font data from CSV /////////////////////////////////////
  useEffect(() => {
    fetch(fontsCSV)
      .then(response => response.text())
      .then(data => {
        const parsedFonts = Papa.parse(data, { header: true });
        setFontsData(parsedFonts.data.map(item => item.font));
      });
  }, []);

  // useEffect to load initial positions from JSON files /////////////////////
  useEffect(() => {
    const loadInitialPositions = async () => {
      const selectedStamp = initialPositions.stamps.find(stamp => stamp.name === selectedName);
      if (selectedStamp) {
        const initialTextPositions = selectedStamp.textPositions.map(item => ({
          ...item,
          position: { x: item.x || 0, y: item.y || 0 },
          fontSize: item.fontSize || 12,
          fontFamily: item.fontFamily || 'Arial',
          letterSpacing: item.letterSpacing || 0,
          wordSpacing: item.wordSpacing || 0,
          fontWeight: item.fontWeight || 'normal',
          fontStyle: item.fontStyle || 'normal',
          textDecoration: item.textDecoration || 'none',
          rotate: item.rotate || 0
        }));
        const initialRectanglePositions = selectedStamp.rectanglePositions.map(item => ({
          ...item,
          position: { x: item.x || 0, y: item.y || 0 },
          width: item.width || 100,
          height: item.height || 100,
          borderWidth: item.borderWidth || 1,
          rotate: item.rotate || 0
        }));
        const initialLinePositions = selectedStamp.linePositions.map(item => ({
          ...item,
          position: { x: item.x || 0, y: item.y || 0 },
          length: item.length || 100,
          thickness: item.thickness || 1,
          rotate: item.rotate || 0
        }));
        setTextData(initialTextPositions);
        setRectangleData(initialRectanglePositions);
        setLineData(initialLinePositions);
        setW(selectedStamp.width);
        setH(selectedStamp.height);
        initialTextData.current = initialTextPositions;
      }
    };

    loadInitialPositions();

    const randomText = teksty[Math.floor(Math.random() * teksty.length)];
    setTextData((prevData) => {
      const newTextData = prevData.map((item, index) => {
        const textKey = `t${index + 1}`;
        return {
          ...item,
          text: randomText[textKey] || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        };
      });
      initialTextData.current = newTextData;
      return newTextData;
    });
  }, [selectedName]);

  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedName(selectedValue);

    const selectedStamp = initialPositions.stamps.find(stamp => stamp.name === selectedValue);
    if (selectedStamp) {
      setTextData(initialTextData.current);
      const initialRectanglePositions = selectedStamp.rectanglePositions.map(item => ({
        ...item,
        position: { x: item.x || 0, y: item.y || 0 },
        width: item.width || 100,
        height: item.height || 100,
        borderWidth: item.borderWidth || 1,
        rotate: item.rotate || 0
      }));
      const initialLinePositions = selectedStamp.linePositions.map(item => ({
        ...item,
        position: { x: item.x || 0, y: item.y || 0 },
        length: item.length || 100,
        thickness: item.thickness || 1,
        rotate: item.rotate || 0
      }));
      setRectangleData(initialRectanglePositions);
      setLineData(initialLinePositions);
      setW(selectedStamp.width);
      setH(selectedStamp.height);
    }
  };

  const handleFontChange = (id, font) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id].fontFamily = font;
      return newData;
    });
  };

  const handleSpacingChange = (id, spacingType, value) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id][spacingType] = parseFloat(value);
      return newData;
    });
  };

  const handleFontWeightChange = (id, value) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id].fontWeight = value;
      return newData;
    });
  };

  const handleFontStyleChange = (id, value) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id].fontStyle = value === 'italic' ? 'italic' : 'normal';
      newData[id].textDecoration = value === 'underline' ? 'underline' : 'none';
      return newData;
    });
  };

  const handleTextChange = (id, value) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id].text = value;
      return newData;
    });
  };

  const handleRotateChange = (id, value) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id].rotate = parseFloat(value);
      return newData;
    });
  };

  const handleRectangleChange = (id, field, value) => {
    setRectangleData((prevData) => {
      const newData = [...prevData];
      newData[id][field] = parseFloat(value);
      return newData;
    });
  };

  const handleLineChange = (id, field, value) => {
    setLineData((prevData) => {
      const newData = [...prevData];
      newData[id][field] = parseFloat(value);
      return newData;
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const height = 100;
          const width = height * aspectRatio;
          setImageData((prevData) => [
            ...prevData,
            {
              id: prevData.length,
              src: e.target.result,
              position: { x: 50, y: 50 },
              width,
              height,
              rotate: 0,
            },
          ]);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const updateImageData = (id, data) => {
    setImageData((prevData) => {
      const newData = [...prevData];
      newData[id] = { ...newData[id], ...data };
      return newData;
    });
  };

  const updateTextData = (id, data) => {
    setTextData((prevData) => {
      const newData = [...prevData];
      newData[id] = { ...newData[id], ...data };
      return newData;
    });
  };

  const updateRectangleData = (id, data) => {
    setRectangleData((prevData) => {
      const newData = [...prevData];
      newData[id] = { ...newData[id], ...data };
      return newData;
    });
  };

  const updateLineData = (id, data) => {
    setLineData((prevData) => {
      const newData = [...prevData];
      newData[id] = { ...newData[id], ...data };
      return newData;
    });
  };

  const generateXML = () => {
    const xmlTexts = textData.map((item, index) => (
      `<DraggableText id="${index}">
        <text>${item.text}</text>
        <fontSize>${item.fontSize}</fontSize>
        <fontFamily>${item.fontFamily}</fontFamily>
        <letterSpacing>${item.letterSpacing}px</letterSpacing>
        <wordSpacing>${item.wordSpacing}px</wordSpacing>
        <fontWeight>${item.fontWeight}</fontWeight>
        <fontStyle>${item.fontStyle}</fontStyle>
        <textDecoration>${item.textDecoration}</textDecoration>
        <position>
          <x>${item.position.x}</x>
          <y>${item.position.y}</y>
        </position>
        <rotate>${item.rotate}</rotate>
      </DraggableText>`
    )).join('\n');
  
    const xmlRectangles = rectangleData.map((item, index) => (
      `<DraggableRectangle id="${index}">
        <width>${item.width}</width>
        <height>${item.height}</height>
        <borderWidth>${item.borderWidth}</borderWidth>
        <rotate>${item.rotate}</rotate>
        <position>
          <x>${item.position.x}</x>
          <y>${item.position.y}</y>
        </position>
      </DraggableRectangle>`
    )).join('\n');
  
    const xmlLines = lineData.map((item, index) => (
      `<DraggableLine id="${index}">
        <length>${item.length}</length>
        <thickness>${item.thickness}</thickness>
        <rotate>${item.rotate}</rotate>
        <position>
          <x>${item.position.x}</x>
          <y>${item.position.y}</y>
        </position>
      </DraggableLine>`
    )).join('\n');
  
    const xmlImages = imageData.map((item, index) => (
      `<DraggableImage id="${index}">
        <src>${item.src}</src>
        <width>${item.width}</width>
        <height>${item.height}</height>
        <rotate>${item.rotate}</rotate>
        <position>
          <x>${item.position.x}</x>
          <y>${item.position.y}</y>
        </position>
      </DraggableImage>`
    )).join('\n');
  
    const finalXml = `<DraggableItems>\n${xmlTexts}\n${xmlRectangles}\n${xmlLines}\n${xmlImages}\n</DraggableItems>`;
    
    const aesKeyWordArray = CryptoJS.lib.WordArray.random(32);
    const aesKeyHex = aesKeyWordArray.toString(CryptoJS.enc.Hex);
    setAesKey(aesKeyHex);

    const ivWordArray = CryptoJS.lib.WordArray.random(16);
    const ivHex = ivWordArray.toString(CryptoJS.enc.Hex);
    setIv(ivHex);

    const encryptedXmlData = CryptoJS.AES.encrypt(finalXml, aesKeyWordArray, {
      iv: ivWordArray,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString();
    setEncryptedXmlData(encryptedXmlData);

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptedAESKey = encrypt.encrypt(aesKeyHex);

    if (!encryptedAESKey) {
      console.error("Encryption of the AES key failed.");
      alert("Encryption of the AES key failed.");
      return;
    }

    fetch('https://r.duplex.pl/ghgh.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        encryptedXmlData,
        encryptedAESKey,
        iv: ivHex
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(result => {
      alert('XML został wysłany pomyślnie!');
    })
    .catch(error => {
      console.error('Error:', error);
      alert(`Wystąpił błąd podczas wysyłania XML: ${error.message}`);
    });
  };

  return (
    <div>
 <select className="dropdown" value={selectedName} onChange={handleNameChange}>
        <option value="">Wybierz nazwę</option>
        {initialPositions.stamps.map((item, index) => (
          <option key={index} value={item.name}>
            {`${item.name} (${item.width / 10} mm x ${item.height / 10} mm)`}
          </option>
        ))}
      </select>     
<button className="image-button" onClick={() => imageInputRef.current.click()}>Obrazek</button>
<button className="save-button" onClick={generateXML}>Wyślij</button>
<input
  ref={imageInputRef}
  type="file"
  accept="image/*"
  style={{ display: 'none' }}
  onChange={handleImageChange}
/>

{selectedElement && selectedElement.type === 'text' && (
  <div className="text-settings active">
    <label className="text-label">Tekst:</label>
    <input
      className="text-input"
      type="text"
      value={textData[selectedElement.id].text}
      onChange={(e) => handleTextChange(selectedElement.id, e.target.value)}
    />
    <label className="dropdown-label">Czcionka:</label>
    <select
      className="font-dropdown"
      value={textData[selectedElement.id].fontFamily}
      onChange={(e) => handleFontChange(selectedElement.id, e.target.value)}
    >
      {fontsData.map((font, index) => (
        <option key={index} value={font}>{font}</option>
      ))}
    </select>
    <label className="number-label">Rozmiar:</label>
    <input
      className="number-input font-size-input"
      type="number"
      value={textData[selectedElement.id].fontSize}
      onChange={(e) => handleSpacingChange(selectedElement.id, 'fontSize', e.target.value)}
      min="1"
      step="0.1"
    />
    <label className="number-label">Odstępy znaków:</label>
    <input
      className="number-input letter-spacing-input"
      type="number"
      value={textData[selectedElement.id].letterSpacing}
      onChange={(e) => handleSpacingChange(selectedElement.id, 'letterSpacing', e.target.value)}
      step="0.1"
    />
    <label className="number-label">Odstęp wyrazów:</label>
    <input
      className="number-input word-spacing-input"
      type="number"
      value={textData[selectedElement.id].wordSpacing}
      onChange={(e) => handleSpacingChange(selectedElement.id, 'wordSpacing', e.target.value)}
      step="0.1"
    />
    <label className="dropdown-label">Grubość:</label>
    <select
      className="font-dropdown"
      value={textData[selectedElement.id].fontWeight}
      onChange={(e) => handleFontWeightChange(selectedElement.id, e.target.value)}
    >
      <option value="normal">Normalny</option>
      <option value="bold">Tłusty</option>
    </select>
    <label className="dropdown-label">Styl:</label>
    <select
      className="font-dropdown"
      value={textData[selectedElement.id].fontStyle === 'italic' ? 'italic' : textData[selectedElement.id].textDecoration === 'underline' ? 'underline' : 'normal'}
      onChange={(e) => handleFontStyleChange(selectedElement.id, e.target.value)}
    >
      <option value="normal">Normalny</option>
      <option value="italic">Kursywa</option>
      <option value="underline">Podkreślenie</option>
    </select>
    <label className="number-label">Obrót:</label>
    <input
      className="number-input rotate-input"
      type="number"
      value={textData[selectedElement.id].rotate}
      onChange={(e) => handleRotateChange(selectedElement.id, e.target.value)}
      step="1"
    />
  </div>
)}

      {selectedElement && selectedElement.type === 'rectangle' && (
        <div className="rectangle-settings active">
          <label className="number-label">Szerokość:</label>
          <input
            className="number-input width-input"
            type="number"
            value={rectangleData[selectedElement.id].width}
            onChange={(e) => handleRectangleChange(selectedElement.id, 'width', e.target.value)}
            step="1"
            min="1"
          />
          <label className="number-label">Wysokość:</label>
          <input
            className="number-input height-input"
            type="number"
            value={rectangleData[selectedElement.id].height}
            onChange={(e) => handleRectangleChange(selectedElement.id, 'height', e.target.value)}
            step="1"
            min="1"
          />
          <label className="number-label">Grubość linii:</label>
          <input
            className="number-input border-width-input"
            type="number"
            value={rectangleData[selectedElement.id].borderWidth}
            onChange={(e) => handleRectangleChange(selectedElement.id, 'borderWidth', e.target.value)}
            step="1"
            min="1"
          />
          <label className="number-label">Obrót:</label>
          <input
            className="number-input rotate-input"
            type="number"
            value={rectangleData[selectedElement.id].rotate}
            onChange={(e) => handleRectangleChange(selectedElement.id, 'rotate', e.target.value)}
            step="1"
          />
        </div>
      )}
      {selectedElement && selectedElement.type === 'line' && (
        <div className="line-settings active">
          <label className="number-label">Długość:</label>
          <input
            className="number-input length-input"
            type="number"
            value={lineData[selectedElement.id].length}
            onChange={(e) => handleLineChange(selectedElement.id, 'length', e.target.value)}
            step="1"
            min="1"
          />
          <label className="number-label">Grubość:</label>
          <input
            className="number-input thickness-input"
            type="number"
            value={lineData[selectedElement.id].thickness}
            onChange={(e) => handleLineChange(selectedElement.id, 'thickness', e.target.value)}
            step="1"
            min="1"
          />
          <label className="number-label">Obrót:</label>
          <input
            className="number-input rotate-input"
            type="number"
            value={lineData[selectedElement.id].rotate}
            onChange={(e) => handleLineChange(selectedElement.id, 'rotate', e.target.value)}
            step="1"
          />
        </div>
      )}
      {selectedElement && selectedElement.type === 'image' && (
        <div className="image-settings active">
          <label className="number-label">Szerokość:</label>
          <input
            className="number-input width-input"
            type="number"
            value={imageData[selectedElement.id].width}
            onChange={(e) => updateImageData(selectedElement.id, { width: e.target.value })}
            step="1"
            min="1"
          />
          <label className="number-label">Wysokość:</label>
          <input
            className="number-input height-input"
            type="number"
            value={imageData[selectedElement.id].height}
            onChange={(e) => updateImageData(selectedElement.id, { height: e.target.value })}
            step="1"
            min="1"
          />
          <label className="number-label">Obrót:</label>
          <input
            className="number-input rotate-input"
            type="number"
            value={imageData[selectedElement.id].rotate}
            onChange={(e) => updateImageData(selectedElement.id, { rotate: e.target.value })}
            step="1"
          />
        </div>
      )}
      {W !== null && H !== null && (
        <Background W={W} H={H} />
      )}
      {textData.map((item, index) => {
        return (
          <DraggableText
            key={index}
            id={index}
            initialPosition={{ x: item.position.x, y: item.position.y }}
            updateTextData={updateTextData}
            setSelectedElement={setSelectedElement}
            fontFamily={item.fontFamily}
            fontSize={item.fontSize}
            letterSpacing={item.letterSpacing}
            wordSpacing={item.wordSpacing}
            fontWeight={item.fontWeight}
            fontStyle={item.fontStyle}
            textDecoration={item.textDecoration}
            text={item.text || 'Przeciągnij mnie'}
            isSelected={selectedElement?.type === 'text' && selectedElement.id === index}
            rotate={item.rotate}
          />
        );
      })}
      {rectangleData.map((item, index) => {
        return (
          <DraggableRectangle
            key={index}
            id={index}
            initialPosition={{ x: item.position.x, y: item.position.y }}
            width={item.width}
            height={item.height}
            borderWidth={item.borderWidth}
            rotate={item.rotate}
            updateRectangleData={updateRectangleData}
            setSelectedElement={setSelectedElement}
            isSelected={selectedElement?.type === 'rectangle' && selectedElement.id === index}
          />
        );
      })}
      {lineData.map((item, index) => {
        return (
          <DraggableLine
            key={index}
            id={index}
            initialPosition={{ x: item.position.x, y: item.position.y }}
            length={item.length}
            thickness={item.thickness}
            rotate={item.rotate}
            updateLineData={updateLineData}
            setSelectedElement={setSelectedElement}
            isSelected={selectedElement?.type === 'line' && selectedElement.id === index}
          />
        );
      })}
      {imageData.map((item, index) => {
        return (
          <DraggableImage
            key={index}
            id={index}
            initialPosition={{ x: item.position.x, y: item.position.y }}
            src={item.src}
            width={item.width}
            height={item.height}
            rotate={item.rotate}
            updateImageData={updateImageData}
            setSelectedElement={setSelectedElement}
            isSelected={selectedElement?.type === 'image' && selectedElement.id === index}
          />
        );
      })}
      <div id="xmlOutput" className="xml-output">
        <pre>{xmlOutput}</pre>
      </div>
    </div>
  );
}

export default App;
